/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-10-2020.
 */
import React from 'react'

const ErrorMessage = ({ errorKey, error, style }) => {
  return error[errorKey] ? (
    <p className="text-danger font-italic p-small mb-1" style={style}>
      {error[errorKey]}
    </p>
  ) : null
}
export default ErrorMessage
