/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/01/2021.
 */
import React from 'react'

import crossSvg from '../assets/cross.svg'

const ToggleBtn = ({ active, onClick, error }) => {
  const size = 24

  const outline = error && !active ? '#dc3545' : '#28355A'

  return (
    <div className="pr-2 noselect ">
      <div onClick={onClick} className="pointer" style={{ height: size, width: size, border: `1px solid ${outline}` }}>
        {active && <img className="p-0 " style={{ verticalAlign: 'baseline' }} src={crossSvg} alt="" />}
      </div>
    </div>
  )
}
export default ToggleBtn
