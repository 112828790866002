/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 18/08/2021.
 */

/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 04-10-2020.
 */
import React, { useRef, useState } from 'react'

import { Link, navigate } from 'gatsby'
import './upload-form.scss'
import ErrorMessage from './ErrorMessage'
import { BASE_URL } from '../../constant'
import ToggleBtn from '../ToggleBtn'
import Spinner from '../spinner/Spinner'

const DemoContactForm = ({}) => {
  const arrowRef = useRef()

  const [state, setState] = useState({
    name: '',
    email: '',
    consent: false,
    tag: 'REQUEST_DEMO_FORM',
  })

  const [loading, setLoading] = useState(false)
  const [formSent, setFormSent] = useState(false)

  const [error, setError] = useState({ name: '', email: '', consent: '' })

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const err = { name: '', email: '', consent: false }

    console.log(' ContactForm > state = ', state)

    // validate name
    if (!state.name) {
      err.name = 'Please enter your name.'
    }

    // validate email
    if (!state.email) {
      err.email = 'Please add a valid email address.'
    }
    if (state.email && (state.email.indexOf('@') === -1 || state.email.indexOf('.') === -1)) {
      err.email = 'Your email is not valid'
    }

    // validate consent
    if (!state.consent) {
      err.consent = 'You need to give consent for us to be allowed to contact you.'
    }

    // we dont if name is missing
    if (err.email || err.consent) {
      setError(err)
      return
    }

    console.log(' ContactForm > sending = ')
    setLoading(true)

    fetch(BASE_URL + '/contactforms', {
      method: 'POST',
      body: JSON.stringify(state),
    })
      .then((res) => {
        setLoading(false)
        console.log(' ContactForm > res = ', res)
        if (res.status !== 200) {
          alert('An error happend - We are sorry please try again')
        }
        return res.json()
      })
      .then((res) => {
        navigate('/request-received', { state: res })
      })
      .catch((error) => alert(error))
  }

  const toggleConsent = () => {
    const newValue = !state.consent
    setState({ ...state, consent: newValue })
    setError({ ...error, consent: newValue })
  }

  const validInput = state.name && state.email && state.consent ? true : false

  return (
    <div className="row mt-4 contact-form" style={{}}>
      <div className="col-12 mx-auto" style={{ maxWidth: 1200 }}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 mb-2">
              <label className="w-100 ">
                {/*<p className="mb-1 p-small">Dit navn:</p>*/}
                <input
                  className="w-100 field"
                  type="name"
                  placeholder="Your name"
                  name="name"
                  onChange={handleChange}
                />
              </label>
              <ErrorMessage errorKey="name" error={error}></ErrorMessage>
            </div>

            <div className="col-12 mb-2">
              <label className="w-100 mb-2">
                {/*<p className="mb-1 p-small ">Din email adresse:</p>*/}
                <input
                  placeholder="Your e-mail"
                  className="w-100 field "
                  type="email"
                  name="email"
                  onChange={handleChange}
                />
              </label>
              <ErrorMessage errorKey="email" error={error}></ErrorMessage>
            </div>

            <div className="col-12 mt-3 d-flex">
              {/*{loading && <Spinner></Spinner>}*/}

              <ToggleBtn onClick={toggleConsent} active={state.consent} error={error.consent}></ToggleBtn>

              <div className="ml-3">
                <p className="mb-0 " style={{ fontSize: '.8rem', lineHeight: 1.3 }}>
                  I consent to my personal data being used with the primary purpose of responding to my inquiry. Read
                  more about how we handle your personal data here.
                  <Link className="ml-1" to={'/terms'}>
                    <u>here</u>
                  </Link>
                  .
                </p>
                <ErrorMessage style={{ marginTop: 4 }} errorKey="consent" error={error}></ErrorMessage>
              </div>
            </div>

            <div className="col-12 d-flex  mb-6">
              {loading && <Spinner style={{ marginRight: 40 }}></Spinner>}

              {!loading && (
                <div className="mt-4 " style={{ opacity: validInput ? 1 : 0.2 }}>
                  <button className="btn btn-primary rounded-pill px-6  mr-4 " type="submit">
                    Request a demo
                  </button>

                  {/*  <img ref={arrowRef} style={{ width: 'auto', height: '2rem' }} src={arrowSvg} alt="" />*/}
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default DemoContactForm
