/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/01/2021.
 */
import React, { useRef } from 'react'
import { useHover } from 'react-use-gesture'
import { gsap } from 'gsap'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import DemoContactForm from '../components/contact/DemoContactForm'

const requestademo = (props) => {
  const arrowRef = useRef()
  const bind = useHover((hoverState) => {
    const to = hoverState.active ? 8 : 0
    gsap.to(arrowRef.current, 0.3, { x: to, ease: 'expo.out' })
  })

  // state will be defined if sent from contact form.
  const locState = props.location.state
  // console.log(' thanks > state = ', locState)
  //sandme_phone_mockup_illustration 1
  return (
    <Layout>
      <div className="container-fluid content-container section-margin">
        <div className="row ">
          <div className="col-12 col-md-6">
            <StaticImage
              className="h-100 w-100"
              objectFit="cover"
              src="../images/sandme_phone_mockup_illustration 1.png"
              objectPosition="50% 50%"
              alt="studies and me"
              quality={100}
              width={1129}
              backgroundColor="transparent"
              placeholder="none"
            ></StaticImage>
          </div>

          <div className="col-12 col-md-6 d-flex align-items-center ">
            <div>
              <h4 className="text-primary mb-4">Request a demo of our approach to decentralized clinical studies</h4>

              <p className="mb-4">
                Get a tour of Studies&Me’s digital study universe. We offer a range of services; from online clinical
                study recruitment to a full End-to-End solution.
              </p>

              <p className="font-weight-bold mt-2">Fill out the form and we will be in touch with you shortly</p>

              <DemoContactForm></DemoContactForm>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default requestademo
